'use client';
import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';

import { Brand } from '@/lib/cachedCms';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';

interface BrandListMobileProps {
  brands: Brand[];
}

export const BrandListMobile = ({ brands }: BrandListMobileProps) => (
  <div className="block md:hidden">
    <Menu as="div" className="relative mt-3 inline-block text-left z-10 w-5/6">
      <div>
        <MenuButton
          className={
            'inline-flex justify-center w-full rounded-sm border border-sage-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-sage-700 hover:bg-sage-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-sage-100 focus:ring-sage-200'
          }
        >
          Manufacturer / Brand
          <FontAwesomeIcon icon={faChevronDown} className="-mr-1 ml-2 h-5 w-50" aria-hidden="true" />
        </MenuButton>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems
          className={
            'origin-top-right mt-2 w-full rounded-sm shadow-md bg-white ring-1 ring-sage-800 ring-opacity-5 focus:outline-none'
          }
        >
          <div className="py-1">
            {brands.map((brand, index) => (
              <MenuItem key={brand.title + index}>
                {({ active }) => (
                  <a
                    className={twMerge(
                      'block px-4 py-2 text-sm',
                      active ? 'self-center bg-gray-100 text-gray-900' : 'self-center text-gray-700',
                    )}
                    href={brand.link.startsWith('/') ? brand.link : `/${brand.link}`}
                  >
                    {brand.title}
                  </a>
                )}
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  </div>
);

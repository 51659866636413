'use client';
import { useState } from 'react';

import { IComponentReviewsSection } from '@/types/cms';
import { faStar, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MAX_REVIEW_LENGTH = 230;

const SingleReview = ({ review }: { review: IComponentReviewsSection['reviews'][number] }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isTooLong = review.review.length > MAX_REVIEW_LENGTH;
  const showReadMore = isTooLong && !isOpen;

  return (
    <li className="rounded-xl bg-white px-8 py-6 flex flex-col items-start gap-3.5 md:p-6 lg:px-10 lg:py-8 lg:gap-3">
      <div className="flex justify-between w-full">
        <div className="flex gap-1 text-yellow-500">
          <FontAwesomeIcon icon={faStar} className="size-5" />
          <FontAwesomeIcon icon={faStar} className="size-5" />
          <FontAwesomeIcon icon={faStar} className="size-5" />
          <FontAwesomeIcon icon={faStar} className="size-5" />
          <FontAwesomeIcon icon={faStar} className="size-5" />
        </div>
        {isTooLong && isOpen && (
          <button type="button" onClick={() => setIsOpen(false)} className="cursor-pointer lg:hidden">
            <FontAwesomeIcon icon={faX} className="size-4 block" />
          </button>
        )}
      </div>
      <p className="text-sm md:text-base lg:hidden">
        {showReadMore ? review.review.slice(0, MAX_REVIEW_LENGTH) + '...' : review.review}
      </p>
      <p className="max-lg:hidden text-base">{review.review}</p>
      {showReadMore && (
        <button
          type="button"
          className="underline text-sm text-blue-500 font-medium hover:text-blue-600 cursor-pointer lg:hidden"
          onClick={() => setIsOpen(true)}
        >
          Read More
        </button>
      )}
      <span className="text-sm font-bold lg:text-base">{review.author}</span>
    </li>
  );
};

export const ReviewsSection = ({ title, description, reviews }: IComponentReviewsSection) => (
  <div className="bg-blue-50 py-16 md:py-24 my-16">
    <div className="font-helvetica text-black-500 px-6 md:px-10 lg:container lg:mx-auto">
      {(title || description) && (
        <div className="flex flex-col gap-4 mb-8 md:mb-11 lg:mb-12">
          {title && <h2 className="text-blue-700 text-2xl font-bold md:text-[2.125rem]">{title}</h2>}
          {description && <p className="text-sm text-blue-700 md:text-base">{description}</p>}
        </div>
      )}

      <ul className="grid grid-cols-1 gap-8 md:grid-cols-2 items-start md:gap-6">
        {reviews.map((review, index) => (
          <SingleReview key={review.author + index} review={review} />
        ))}
      </ul>
    </div>
  </div>
);

'use client';
import Link from 'next/link';
import { CSSProperties } from 'react';
import Slider from 'react-slick';
import { twMerge } from 'tailwind-merge';

import { IComponentHomeHero } from '@/types/cms';
import { faArrowLeft, faArrowRight, faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CustomArrowProps {
  isRight?: boolean;
  onClick?: () => void;
}

const CustomArrow = ({ isRight, onClick }: CustomArrowProps) => (
  <button
    type="button"
    onClick={onClick}
    className={twMerge(
      'absolute top-1/2 -translate-y-1/2 flex items-center justify-center cursor-pointer z-10 outline-none size-9 md:size-7 lg:size-10 md:rounded-full text-white max-md:hover:text-gray-100 md:text-black-500 md:bg-white md:hover:bg-gray-100',
      isRight ? 'right-0 md:right-4 lg:right-8' : 'left-0 md:left-4 lg:left-8',
    )}
  >
    <FontAwesomeIcon
      className="size-6 drop-shadow-[0px_0px_3px_0px_#00000080] md:hidden"
      icon={isRight ? faChevronRight : faChevronLeft}
    />
    <FontAwesomeIcon className="size-3 lg:size-4 max-md:hidden" icon={isRight ? faArrowRight : faArrowLeft} />
  </button>
);

const CustomPaging = () => (
  <div className="size-3 md:size-2.5 lg:size-3.5 rounded-full border border-white p-0.5 cursor-pointer hover:border-gray-100">
    <figure className="size-full rounded-full" />
  </div>
);

const overlayGradientStylesMap: Record<string, string> = {
  'top-bottom': 'bg-gradient-to-b',
  'bottom-top': 'bg-gradient-to-t',
  'left-right': 'bg-gradient-to-r',
  'right-left': 'bg-gradient-to-l',
  'radial-in-out': 'bg-gradient-radial from-[var(--overlay-gradient-to)] to-[var(--overlay-gradient-from)]',
  'radial-out-in': 'bg-gradient-radial',
};

const hexWithOpacity = (hex: string, percent: number, fallback = '#000000') => {
  hex = hex.replace(/^#/, '');

  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((c) => c + c)
      .join('');
  }

  if (!/^([0-9a-f]{6})$/i.test(hex)) {
    return fallback;
  }

  let p = Number(percent);
  if (isNaN(p)) p = 100;
  p = Math.max(0, Math.min(100, p));

  const alpha = Math.round((p / 100) * 255);
  const alphaHex = alpha.toString(16).padStart(2, '0');

  return `#${hex}${alphaHex}`;
};

interface HomeHeroOverlayProps {
  include_overlay_text: boolean;
  overlay_text: string;
  overlay_text_color: string;
  include_overlay_subtext: boolean;
  overlay_subtext: string;
  overlay_subtext_color: string;
  include_overlay_cta: boolean;
  overlay_cta_text: string;
  overlay_cta_link: string;
  overlay_cta_background_color: string;
  overlay_cta_text_color: string;
  overlay_cta_hover_background_color: string;
  overlay_cta_hover_text_color: string;
  include_overlay_gradient: boolean;
  overlay_gradient_style: string;
  overlay_gradient_from: string;
  overlay_gradient_from_opacity: number;
  overlay_gradient_to: string;
  overlay_gradient_to_opacity: number;
  className?: string;
}

const HomeHeroOverlay = (props: HomeHeroOverlayProps) => {
  if (
    !props.include_overlay_cta &&
    !props.include_overlay_gradient &&
    !props.include_overlay_subtext &&
    !props.include_overlay_text
  )
    return null;

  return (
    <div
      style={
        {
          '--overlay-gradient-from': hexWithOpacity(
            props.overlay_gradient_from,
            props.overlay_gradient_from_opacity,
            'transparent',
          ),
          '--overlay-gradient-to': hexWithOpacity(
            props.overlay_gradient_to,
            props.overlay_gradient_to_opacity,
            '#000000',
          ),
        } as CSSProperties
      }
      className={twMerge(
        'absolute bottom-0 left-0 size-full flex flex-col justify-center items-center text-white font-helvetica from-[var(--overlay-gradient-from)] to-[var(--overlay-gradient-to)]',
        props.include_overlay_gradient ? overlayGradientStylesMap[props.overlay_gradient_style] : 'bg-none',
        props.className,
      )}
    >
      {props.include_overlay_text && (
        <h2
          className="text-3xl font-bold md:text-5xl"
          style={{
            color: props.overlay_text_color || 'white',
          }}
        >
          {props.overlay_text}
        </h2>
      )}

      {props.include_overlay_subtext && (
        <p
          className="mt-4 md:mt-2.5 text-sm md:text-base"
          style={{
            color: props.overlay_subtext_color || 'white',
          }}
        >
          {props.overlay_subtext}
        </p>
      )}

      {props.include_overlay_cta && (
        <Link
          href={props.overlay_cta_link || '#'}
          style={
            {
              '--cta-background-color': props.overlay_cta_background_color || 'white',
              '--cta-text-color': props.overlay_cta_text_color || '#21201F',
              '--cta-hover-background-color': props.overlay_cta_hover_background_color || '#F5F5F5',
              '--cta-hover-text-color': props.overlay_cta_hover_text_color || '#21201F',
            } as CSSProperties
          }
          className="mt-6 md:mt-5 px-8 md:px-9 py-2.5 md:py-3 rounded-md flex items-center justify-center text-sm md:text-base font-bold bg-[var(--cta-background-color)] text-[var(--cta-text-color)] hover:bg-[var(--cta-hover-background-color)] hover:text-[var(--cta-hover-text-color)]"
        >
          {props.overlay_cta_text}
        </Link>
      )}
    </div>
  );
};

export const HomeHero = ({ images, speed: rawSpeed }: IComponentHomeHero) => {
  const speed = rawSpeed ? Number(rawSpeed) * 1000 : undefined;

  return (
    <div className="px-6 md:px-10 my-8 md:my-10 lg:my-16 lg:container lg:mx-auto">
      <Slider
        slidesToScroll={1}
        slidesToShow={1}
        infinite={images.length > 1}
        arrows
        nextArrow={<CustomArrow isRight />}
        prevArrow={<CustomArrow />}
        dots
        dotsClass="absolute bottom-5 md:bottom-3 lg:bottom-5 right-1/2 !flex gap-1.5 translate-x-1/2 [&>li.slick-active_figure]:!bg-white z-10"
        customPaging={CustomPaging}
        adaptiveHeight
        autoplay
        autoplaySpeed={isNaN(speed) || !speed ? 3000 : speed}
      >
        {images.map((image, index) => {
          const Container = image.overlay_link ? Link : 'div';

          return (
            <Container
              href={image.overlay_link || undefined}
              key={image.alt + index}
              className="relative rounded-xl md:rounded lg:rounded-[0.625rem] overflow-hidden"
            >
              <img src={image.mobile_url} alt={image.alt} className="size-full object-cover min-[430px]:hidden" />
              <img
                src={image.large_mobile_url}
                alt={image.alt}
                className="size-full object-cover max-[429px]:hidden md:hidden"
              />
              <img src={image.tablet_url} alt={image.alt} className="size-full object-cover max-md:hidden lg:hidden" />
              <img
                src={image.small_desktop_url}
                alt={image.alt}
                className="size-full object-cover max-lg:hidden min-[1440px]:hidden"
              />
              <img
                src={image.medium_desktop_url}
                alt={image.alt}
                className="size-full object-cover max-[1439px]:hidden min-[1920px]:hidden"
              />
              <img
                src={image.large_desktop_url}
                alt={image.alt}
                className="size-full object-cover max-[1919px]:hidden"
              />

              <HomeHeroOverlay
                include_overlay_text={image.mobile_include_overlay_text}
                overlay_text={image.mobile_overlay_text}
                overlay_text_color={image.mobile_overlay_text_color}
                include_overlay_subtext={image.mobile_include_overlay_subtext}
                overlay_subtext={image.mobile_overlay_subtext}
                overlay_subtext_color={image.mobile_overlay_subtext_color}
                include_overlay_cta={image.mobile_include_overlay_cta}
                overlay_cta_text={image.mobile_overlay_cta_text}
                overlay_cta_link={image.mobile_overlay_cta_link}
                overlay_cta_background_color={image.mobile_overlay_cta_background_color}
                overlay_cta_text_color={image.mobile_overlay_cta_text_color}
                overlay_cta_hover_background_color={image.mobile_overlay_cta_hover_background_color}
                overlay_cta_hover_text_color={image.mobile_overlay_cta_hover_text_color}
                include_overlay_gradient={image.mobile_include_overlay_gradient}
                overlay_gradient_style={image.mobile_overlay_gradient_style}
                overlay_gradient_from={image.mobile_overlay_gradient_from}
                overlay_gradient_from_opacity={image.mobile_overlay_gradient_from_opacity}
                overlay_gradient_to={image.mobile_overlay_gradient_to}
                overlay_gradient_to_opacity={image.mobile_overlay_gradient_to_opacity}
                className="md:hidden"
              />
              <HomeHeroOverlay
                include_overlay_text={image.tablet_include_overlay_text}
                overlay_text={image.tablet_overlay_text}
                overlay_text_color={image.tablet_overlay_text_color}
                include_overlay_subtext={image.tablet_include_overlay_subtext}
                overlay_subtext={image.tablet_overlay_subtext}
                overlay_subtext_color={image.tablet_overlay_subtext_color}
                include_overlay_cta={image.tablet_include_overlay_cta}
                overlay_cta_text={image.tablet_overlay_cta_text}
                overlay_cta_link={image.tablet_overlay_cta_link}
                overlay_cta_background_color={image.tablet_overlay_cta_background_color}
                overlay_cta_text_color={image.tablet_overlay_cta_text_color}
                overlay_cta_hover_background_color={image.tablet_overlay_cta_hover_background_color}
                overlay_cta_hover_text_color={image.tablet_overlay_cta_hover_text_color}
                include_overlay_gradient={image.tablet_include_overlay_gradient}
                overlay_gradient_style={image.tablet_overlay_gradient_style}
                overlay_gradient_from={image.tablet_overlay_gradient_from}
                overlay_gradient_from_opacity={image.tablet_overlay_gradient_from_opacity}
                overlay_gradient_to={image.tablet_overlay_gradient_to}
                overlay_gradient_to_opacity={image.tablet_overlay_gradient_to_opacity}
                className="max-md:hidden lg:hidden"
              />
              <HomeHeroOverlay
                include_overlay_text={image.desktop_include_overlay_text}
                overlay_text={image.desktop_overlay_text}
                overlay_text_color={image.desktop_overlay_text_color}
                include_overlay_subtext={image.desktop_include_overlay_subtext}
                overlay_subtext={image.desktop_overlay_subtext}
                overlay_subtext_color={image.desktop_overlay_subtext_color}
                include_overlay_cta={image.desktop_include_overlay_cta}
                overlay_cta_text={image.desktop_overlay_cta_text}
                overlay_cta_link={image.desktop_overlay_cta_link}
                overlay_cta_background_color={image.desktop_overlay_cta_background_color}
                overlay_cta_text_color={image.desktop_overlay_cta_text_color}
                overlay_cta_hover_background_color={image.desktop_overlay_cta_hover_background_color}
                overlay_cta_hover_text_color={image.desktop_overlay_cta_hover_text_color}
                include_overlay_gradient={image.desktop_include_overlay_gradient}
                overlay_gradient_style={image.desktop_overlay_gradient_style}
                overlay_gradient_from={image.desktop_overlay_gradient_from}
                overlay_gradient_from_opacity={image.desktop_overlay_gradient_from_opacity}
                overlay_gradient_to={image.desktop_overlay_gradient_to}
                overlay_gradient_to_opacity={image.desktop_overlay_gradient_to_opacity}
                className="max-lg:hidden"
              />
            </Container>
          );
        })}
      </Slider>
    </div>
  );
};
